.authenticator-background  {
  height: 100%;
  margin: 0;
  background-image: url("components/images/homepg.jpg")!important;
  background-size: cover;
  background-position: center;
  background-blend-mode: overlay;
  background-color: rgb(75, 75, 75);
}

/* .authenticator-background {
  background-image: url('path/to/your/background-image.jpg');
  background-size: cover;
  background-position: center;
} */

/* Ensures the Authenticator takes full viewport height to display background properly */
.authenticator-container {
  min-height: 100vh; /* Adjust based on your layout needs */
  /* height: 100%; */
  margin: 0;
  background-image: url("components/images/homepg.jpg")!important;
  background-size: cover;
  background-position: center;
  background-blend-mode: overlay;
  background-color: rgb(75, 75, 75);
}

/* body, html {
  height: 100%;
  margin: 0;
  background-image: url("components/images/homepg.jpg")!important;
  background-size: cover;
  background-position: center;
  background-blend-mode: overlay;
  background-color: rgb(75, 75, 75);
}

#root {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;

}

.flex-col {
  width: 100%; /* Adjust the width as needed */
  /* max-width: 450px; Maximum width for the Authenticator */
/* } */ 

/* [data-amplify-authenticator][data-variation=modal]{
  background-image: url("components/images/homepg.jpg")!important;
  background-size: cover;
  background-position: center;
  background-blend-mode: overlay;
  /* Adjust the tint color and opacity */
  /* background-color: rgb(75, 75, 75); */
/* } */ 

/* 
.amplify-tabs[data-indicator-position=top] {
  display: none!important;
  visibility: hidden!important;
}
.amplify-tabs {
  display: none!important;
  visibility: hidden!important;
}
[data-amplify-authenticator] [data-amplify-router]{
  background-color: #b5b5b5 !important;

}

.amplify-authenticator-header {
  background-color: #f2f2f2!important;
  color: #333!important;
  padding: 10px!important;
}
[data-amplify-authenticator] [data-amplify-header] {
  background-color: #f2f2f2!important;
  color: #333!important;
  padding: 10px!important;
}

.amplify-input {
  border-color: #4d1515 !important;
}

.amplify-button {
  border-color: #4d1515 !important;
}
.amplify-button--primary {
  background-color: #4d1515!important;
}

.amplify-button--link {
  color: #4d1515!important;
}

[data-amplify-authenticator] [data-amplify-container]{
  background-color: #f2f2f2!important;
}
[data-amplify-authenticator][data-variation=modal]{
  background-image: url("components/images/homepg.jpg")!important;
  background-size: cover;
  background-position: center;
  background-blend-mode: overlay;
  /* Adjust the tint color and opacity */
  /* background-color: rgb(75, 75, 75); Replace with your desired tint color and opacity */
/* } */
/* 

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
} */ 
